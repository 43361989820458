import React from "react";

//Special
const PageError = React.lazy(() => import("./pages/PageError"));

// Import all components
const Home = React.lazy(() => import("./pages/Home"));

const PagePortfolio = React.lazy(() => import("./pages/PagePortfolio"));
const PageAboutMe = React.lazy(() => import("./pages/PageAboutMe"));

//Contact
const PageContactDetail = React.lazy(() =>
  import("./pages/PageContactDetail")
);

// Portfolio

const PF_GPerf = React.lazy(() => import("./pages/PortfolioDetails/GPerf"));
const PF_JPHabitat = React.lazy(() => import("./pages/PortfolioDetails/JPHabitat"));
const PF_3B = React.lazy(() => import("./pages/PortfolioDetails/3B"));
const PF_ByMajestic = React.lazy(() => import("./pages/PortfolioDetails/ByMajestic"));

const routes = [

  { path: "/a-propos", component: PageAboutMe },
  { path: "/contact", component: PageContactDetail, isWithoutLayout: true },
  
  { path: "/portfolio", component: PagePortfolio },
  { path: "/portfolio/g-perf", component: PF_GPerf },
  { path: "/portfolio/jp-habitat", component: PF_JPHabitat },
  { path: "/portfolio/bigeard-bois-et-buches", component: PF_3B },
  { path: "/portfolio/bymajestic", component: PF_ByMajestic },
  
  //Index root
  { path: "/", component: Home, exact: true },
  { path: "/index", component: Home },
  { path: "/error", component: PageError, isWithoutLayout: true },
  { component: PageError, isWithoutLayout: true, exact: false },
];

export default routes;
